<script>
import { onMounted, ref } from "vue";
import CardToolbar from "@/common/components/CardToolbar.vue";
import LineAndAreaChart from "@/common/components/LineAndAreaChart.vue";
import EmptyCardLoading from "@/common/components/EmptyCardLoading.vue";
import { getTrackStreamsByChartmetricTrackID } from "@/api/tracks.api";
import { getTrackStreamsDailyDiffByChartmetricTrackID } from "@/api/tracks.api";
import { useRoute } from "vue-router";
import { ElNotification } from "element-plus";
export default {
  name: "TrackStreams",
  components: { CardToolbar, EmptyCardLoading, LineAndAreaChart },
  setup() {
    const loading = ref(false);
    const chartSeries = ref([]);
    const categories = ref([]);
    const chartSeriesDailyDiff = ref([]);
    const categoriesDailyDiff = ref([]);
    const route = useRoute();
    onMounted(() => {
      getTrackStreams();
      getTrackStreamsDailyDiff();
    });
    const getTrackStreams = async () => {
      try {
        loading.value = true;
        const { data } = await getTrackStreamsByChartmetricTrackID(
          route.params.chartmetricTrackID
        );
        chartSeries.value = [];
        categories.value = data.data && data.data.xAxis ? data.data.xAxis : [];
        chartSeries.value.push({
          name: "Streams",
          data: data.data && data.data.yAxis ? data.data.yAxis : [],
        });
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in fetching track streams",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };

    const getTrackStreamsDailyDiff = async () => {
      try {
        loading.value = true;
        const { data } = await getTrackStreamsDailyDiffByChartmetricTrackID(
          route.params.chartmetricTrackID
        );

        categoriesDailyDiff.value =
          data.data && data.data.xAxis ? data.data.xAxis : [];
        chartSeriesDailyDiff.value.push({
          name: "Streams",
          data: data.data && data.data.yAxis ? data.data.yAxis : [],
        });
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in fetching track streams daily diff",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };
    return {
      loading,
      chartSeries,
      categories,
      chartSeriesDailyDiff,
      categoriesDailyDiff,
      getTrackStreams,
      getTrackStreamsDailyDiff,
    };
  },
};
</script>
<template>
  <div class="mt-2 track-streams-section">
    <EmptyCardLoading
      :loading="loading"
      :type="'skeleton'"
      v-if="loading"
      :card-height="'400px'"
      :rows="9"
      class="mb-5 mb-xl-10"
    />
    <CardToolbar
      v-if="!loading && categories.length > 0 && chartSeries.length > 0"
      title=""
      :show-toolbar-buttons="false"
      :fontStyle="true"
      :margin="true"
      :padding="true"
      :shadow-class="true"
      :show-header="false"
    >
      <template v-slot:body>
        <LineAndAreaChart
          :chart-height="400"
          :categories="categories"
          :chart-series="chartSeries"
          :chart-type="'area'"
          :chart-stroke="{
            curve: 'straight',
            show: true,
            width: [3, 1],
            dashArray: [0, 8],
          }"
          :tooltip-x-format="'MMM DD, YYYY'"
        />
      </template>
    </CardToolbar>
  </div>

  <div id="track-streams-daily-diff-section">
    <div class="artist-content-heading">Track Streams Daily Diff</div>
    <div class="mt-2 track-streams-daily-diff-section">
      <EmptyCardLoading
        :loading="loading"
        :type="'skeleton'"
        v-if="loading"
        :card-height="'400px'"
        :rows="9"
        class="mb-5 mb-xl-10"
      />
      <CardToolbar
        v-if="
          !loading &&
          categoriesDailyDiff.length > 0 &&
          chartSeriesDailyDiff.length > 0
        "
        title=""
        :show-toolbar-buttons="false"
        :fontStyle="true"
        :margin="true"
        :padding="true"
        :shadow-class="true"
        :show-header="false"
      >
        <template v-slot:body>
          <LineAndAreaChart
            :chart-height="400"
            :categories="categoriesDailyDiff"
            :chart-series="chartSeriesDailyDiff"
            :chart-type="'area'"
            :chart-stroke="{
              curve: 'straight',
              show: true,
              width: [3, 1],
              dashArray: [0, 8],
            }"
            :tooltip-x-format="'MMM DD, YYYY'"
          />
        </template>
      </CardToolbar>
    </div>
  </div>
</template>
