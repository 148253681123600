<script>
import SubTopNavBar from "@/common/components/SubTopNavBar.vue";
import KpiCardV2 from "@/common/components/KpiCardV2.vue";
import PlaylistPositionsTable from "@/common/components/PlaylistPositionsTable.vue";
import TrackStreams from "@/playlistTracks/components/TrackStreams.vue";
import MusicPlaylists from "@/common/components/MusicPlaylists.vue";
import RecommendedPlaylists from "@/common/components/RecommendedPlaylists.vue";

import { onMounted, ref } from "vue";
import { getTrackDetailInAllPlaylistsByChartmetricTrackID } from "@/api/playlists.api";
import { useRoute } from "vue-router";
import { checkIsValueAvailableOrNot } from "@/utils/staticHelper";
import {
  getPlaylistRecommendations,
  getPlaylistSimilar,
  getTrackDetailByChartmetricTrackID,
  saveTrackDetailByChartmetricID,
} from "@/api/tracks.api";
import { ElNotification } from "element-plus";
export default {
  name: "TrackDetail",
  methods: { checkIsValueAvailableOrNot },
  components: {
    SubTopNavBar,
    KpiCardV2,
    PlaylistPositionsTable,
    TrackStreams,
    MusicPlaylists,
    RecommendedPlaylists,
  },
  setup() {
    const loading = ref(false);
    const playlistLoading = ref(false);
    const updateLoading = ref(false);
    const trackDetail = ref({});
    const currentPlaylists = ref([]);
    const pastPlaylists = ref([]);
    const playlists = ref([]);
    const route = useRoute();
    const offset = ref(undefined);
    const pageLimit = ref(undefined);
    const tableColumns = ref([
      "playlistName",
      "ownerName",
      "followers",
      "fdiffMonth",
      "position",
      "addedAt",
      "period",
    ]);
    const tabMenuList = ref([
      {
        id: "playlist_track_statistics",
        name: "Statistics",
        icon: "/media/svg/playlist.svg",
        alt: "statistics",
      },
      {
        id: "playlist_position_table",
        name: "Playlist Positions",
        icon: "/media/svg/playlist.svg",
        alt: "playlistPositions",
      },
      {
        id: "similar_playlist_table",
        name: "Similar Playlists",
        icon: "/media/svg/playlist.svg",
        alt: "similarPlaylist",
      },
      {
        id: "recommended_playlist_table",
        name: "Recommended Playlists",
        icon: "/media/svg/playlist.svg",
        alt: "recommendedPlaylist",
      },
      {
        id: "track_streams",
        name: "Track Streams",
        icon: "/media/svg/playlist.svg",
        alt: "trackStreams",
      },
    ]);
    const similarPlaylists = ref([]);
    const similarPlaylistLoading = ref(false);
    const pageNumberCount = ref(undefined);
    const recommendedPlaylists = ref([]);
    const recommendedPlaylistLoading = ref(false);

    onMounted(() => {
      document.title = "Track";
      getPlaylistTrackDetail();
      getPlaylistTracks();
      fetchSimilarPlaylistsData();
    });
    const getOffsetData = (payload) => {
      offset.value = (payload.pageNumber - 1) * payload.limit;
      pageLimit.value = payload.limit;
      fetchRecommendedPlaylistsData();
    };
    const getPlaylistTrackDetail = async () => {
      try {
        loading.value = true;
        const { data } = await getTrackDetailByChartmetricTrackID(
          route.params.chartmetricTrackID
        );
        trackDetail.value = data && data.data ? data.data : {};

        document.title =
          trackDetail.value && trackDetail.value.trackName
            ? "Track | " + trackDetail.value.trackName
            : "Track";
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in fetching track details",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };

    const getPlaylistTracks = async () => {
      try {
        playlistLoading.value = true;
        const { data } = await getTrackDetailInAllPlaylistsByChartmetricTrackID(
          route.params.chartmetricTrackID
        );
        if (data && data.data) {
          currentPlaylists.value =
            data.data.currentPlaylistPositions &&
            data.data.currentPlaylistPositions.length
              ? data.data.currentPlaylistPositions
              : [];
          pastPlaylists.value =
            data.data.pastPlaylistPositions &&
            data.data.pastPlaylistPositions.length
              ? data.data.pastPlaylistPositions
              : [];

          playlists.value = [...currentPlaylists.value, ...pastPlaylists.value];
        }
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in fetching track playlists",
          type: "error",
        });
      } finally {
        playlistLoading.value = false;
      }
    };

    const fetchSimilarPlaylistsData = async () => {
      try {
        similarPlaylistLoading.value = true;
        const { data } = await getPlaylistSimilar(
          route.params.chartmetricTrackID
        );
        if (data && data.data && data.data.recommendedPlaylists) {
          similarPlaylists.value = data.data.recommendedPlaylists;
        }
      } catch (e) {
        console.log(e);
      } finally {
        similarPlaylistLoading.value = false;
      }
    };

    const fetchRecommendedPlaylistsData = async () => {
      try {
        recommendedPlaylistLoading.value = true;
        const { data } = await getPlaylistRecommendations(
          route.params.chartmetricTrackID,
          offset.value,
          pageLimit.value
        );
        if (data && data.data && data.data.recommendedPlaylists) {
          recommendedPlaylists.value = data.data.recommendedPlaylists;
          pageNumberCount.value = data.data.count;
        }
      } catch (e) {
        console.log("error", e);
      } finally {
        recommendedPlaylistLoading.value = false;
      }
    };

    const updateDetail = async () => {
      try {
        updateLoading.value = true;
        await saveTrackDetailByChartmetricID(
          route.params.chartmetricTrackID,
          true
        );
        ElNotification({
          title: "Success",
          message: "Request sent for updating track details",
          type: "success",
        });
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in updating track details",
          type: "error",
        });
      } finally {
        updateLoading.value = false;
      }
    };

    return {
      loading,
      playlistLoading,
      updateLoading,
      tabMenuList,
      trackDetail,
      currentPlaylists,
      pastPlaylists,
      tableColumns,
      playlists,
      similarPlaylists,
      similarPlaylistLoading,
      recommendedPlaylists,
      recommendedPlaylistLoading,
      updateDetail,
      getOffsetData,
      pageNumberCount,
      // offset
    };
  },
};
</script>
<template>
  <div
    id="playlist-track-detail"
    class="d-flex justify-content-start"
    v-loading.fullscreen.lock="loading"
  >
    <div class="playlist-main-content w-100">
      <div class="d-flex page-heading">
        Track :
        {{ trackDetail && trackDetail.trackName ? trackDetail.trackName : "" }}
      </div>
      <sub-top-nav-bar
        :tabs-list="tabMenuList"
        current-active-tab="#playlist_track_statistics"
        main-id="playlist_menu_list"
        :sub-list="[]"
        :scroll-to-element-margin-main="80"
        sub-id="playlist_sub_id"
        :have-sub-list="false"
        :update-button="true"
        @updateDetail="updateDetail"
        :loading="updateLoading"
      />
      <div class="row mt-8">
        <div id="playlist_track_statistics">
          <div class="artist-content-heading">Playlist Track Statistics</div>
          <div class="mt-2 mb-3 mb-xl-5">
            <el-row :gutter="12">
              <el-col
                class="margin-bottom-12"
                :lg="6"
                :md="6"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="
                    trackDetail &&
                    trackDetail.chartmetricArtistTrackRelease &&
                    trackDetail.chartmetricArtistTrackRelease.artist &&
                    trackDetail.chartmetricArtistTrackRelease.artist.length > 0
                      ? trackDetail.chartmetricArtistTrackRelease.artist[0].name
                      : 'N/A'
                  "
                  :text="'Artist Name'"
                />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="6"
                :md="6"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="
                    checkIsValueAvailableOrNot(
                      'object',
                      trackDetail,
                      'trackName'
                    )
                  "
                  :text="'Track Name'"
                />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="6"
                :md="6"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="
                    checkIsValueAvailableOrNot('date', trackDetail, 'timestp')
                  "
                  :text="'Release Date'"
                /> </el-col
              ><el-col
                class="margin-bottom-12"
                :lg="6"
                :md="6"
                :sm="8"
                :xs="24"
              >
                <kpi-card-v2
                  :title="
                    checkIsValueAvailableOrNot(
                      'object',
                      trackDetail &&
                        trackDetail.chartmetricArtistTrackRelease &&
                        trackDetail.chartmetricArtistTrackRelease.latest
                        ? trackDetail.chartmetricArtistTrackRelease.latest
                        : {},
                      'spotify_popularity'
                    )
                  "
                  :text="'Spotify Popularity'"
                />
              </el-col>
            </el-row>
          </div>
        </div>
        <div id="playlist_position_table">
          <div class="artist-content-heading">Playlists Positions</div>
          <PlaylistPositionsTable
            :title="'Current Playlists'"
            :playlist-loading="playlistLoading"
            :playlist-data="currentPlaylists"
            :table-columns="tableColumns"
            :margin-class="'margin-bottom-12'"
            :show-similar-playlist-button="true"
            :playlists="playlists"
          />
          <PlaylistPositionsTable
            :title="'Past Playlists'"
            :playlist-loading="playlistLoading"
            :playlist-data="pastPlaylists"
            :table-columns="tableColumns"
          />
        </div>
        <div id="similar_playlist_table">
          <div class="artist-content-heading mb-2">Similar Playlists</div>
          <MusicPlaylists
            v-if="trackDetail && trackDetail.chartmetricID"
            :playlist-data="similarPlaylists"
            :playlist-loading="similarPlaylistLoading"
            :chartmetricID="trackDetail.chartmetricID"
          />
        </div>

        <div id="recommended_playlist_table">
          <div class="artist-content-heading mb-2">Recommended Playlists</div>
          <RecommendedPlaylists
            v-if="trackDetail && trackDetail.chartmetricID"
            :playlist-data="recommendedPlaylists"
            :playlist-loading="recommendedPlaylistLoading"
            :chartmetricID="trackDetail.chartmetricID"
            v-on:get-offset-data="getOffsetData"
            :getCount="pageNumberCount"
          />
        </div>

        <div id="track_streams">
          <div class="artist-content-heading">Track Streams</div>
          <TrackStreams />
        </div>
      </div>
    </div>
  </div>
</template>
