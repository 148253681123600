<script>
import { ref, watch, onMounted } from "vue";
import {
  formatNumberIntoHumanizeForm,
  sortArray,
  tableCellClassName,
  tableRowClassName,
} from "@/utils/staticHelper";
import moment from "moment/moment";
import CardToolbar from "@/common/components/CardToolbar.vue";
import { TypeEnums } from "@/common/enums/typeEnums";
import { deleteRecommendedPlaylist } from "@/api/tracks.api";
import { useRoute } from "vue-router";
import { ElNotification } from "element-plus";
import PlaylistWishlistModal from "@/common/components/PlaylistWishlistModal.vue";
import Swal from "sweetalert2";
export default {
  name: "MusicPlaylists",
  computed: {
    TypeEnums() {
      return TypeEnums;
    },
  },
  methods: {
    formatNumberIntoHumanizeForm,
    tableCellClassName,
    tableRowClassName,
  },
  components: { CardToolbar, PlaylistWishlistModal },
  props: {
    playlistData: {
      type: Array,
      required: true,
    },
    playlistLoading: {
      type: Boolean,
      default: false,
    },
    cardTitle: {
      type: String,
      default: "",
    },
    chartmetricID: {
      type: Number,
      required: true,
    },
    showToolbarButtons: {
      type: Boolean,
      default: true,
    },
    gettoffsetData: {
      type: Function,
      required: true,
    },
    getCount: {
      type: Number,
      required: true,
    },
  },
  emits: ["getOffsetData"],
  setup(props, { emit }) {
    onMounted(() => {
      emit("getOffsetData", {
        pageNumber: localPagination.value.offset,
        limit: localPagination.value.limit,
      });
    });
    const loading = ref(true);
    const route = useRoute();
    const tableData = ref([]);
    const sortBy = ref(null);
    const sortDir = ref(null);
    const localPagination = ref({
      offset: 1,
      limit: 10,
      total: 20,
      sortBy: "followers",
      sortOrder: "desc",
    });
    const selectedPlaylists = ref([]);
    const allSelected = ref(false);
    const sortResults = ({ prop, order }) => {
      sortBy.value = prop;
      sortDir.value = order;
      if (sortBy.value && sortDir.value) {
        tableData.value = sortArray(
          sortBy.value,
          sortDir.value,
          JSON.parse(JSON.stringify(tableData.value))
        );
      }
    };

    const checkBoxValueChanged = (event, id) => {
      allSelected.value = false;
      if (event && !selectedPlaylists.value.includes(id)) {
        selectedPlaylists.value.push(id);
      } else if (!event) {
        const index = selectedPlaylists.value.indexOf(id);
        if (index > -1) {
          selectedPlaylists.value.splice(index, 1);
        }
      }
    };

    const selectAllClicked = (value) => {
      if (value) {
        selectedPlaylists.value = tableData.value.map(
          (object) => object.curatorPlaylistID
        );
      } else {
        selectedPlaylists.value = [];
      }
    };

    const deletePlaylists = async () => {
      Swal.fire({
        title: "Delete this Playlists?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            loading.value = true;
            const { data } = await deleteRecommendedPlaylist(
              route.params.chartmetricTrackID,
              selectedPlaylists.value
            );
            tableData.value =
              data &&
              data.data &&
              data.data.recommendedPlaylists &&
              data.data.recommendedPlaylists.length
                ? data.data.recommendedPlaylists
                : [];
            ElNotification({
              title: "Success",
              message: "Playlists removed successfully",
              type: "success",
            });
          }
        })
        .catch((e) => {
          console.error(e);
          Swal.fire("Oops!", "Error in deleting playlists.", "error");
        })
        .finally(() => {
          loading.value = false;
        });
    };

    watch(
      () => props.playlistData,
      (value) => {
        if (value.length > 0) {
          tableData.value = value;
        }
      },
      { immediate: true, deep: true }
    );

    const updatePlaylists = () => {
      selectedPlaylists.value = [];
      allSelected.value = false;
    };

    watch(
      () => props.playlistLoading,
      (value) => {
        loading.value = value;
      },
      { immediate: true, deep: true }
    );
    watch(
      () => [localPagination.value.offset, localPagination.value.limit],
      () => {
        emit("getOffsetData", {
          pageNumber: localPagination.value.offset,
          limit: localPagination.value.limit,
        });
      }
    );
    return {
      loading,
      tableData,
      sortBy,
      sortDir,
      localPagination,
      selectedPlaylists,
      allSelected,
      sortResults,
      moment,
      checkBoxValueChanged,
      selectAllClicked,
      deletePlaylists,
      updatePlaylists,
    };
  },
};
</script>
<template>
  <CardToolbar
    :title="cardTitle"
    :show-toolbar-buttons="showToolbarButtons"
    :margin="true"
    :fontStyle="true"
    :padding="!showToolbarButtons"
    :padding-left="false"
    :shadow-class="true"
    :show-header="showToolbarButtons"
  >
    <template v-slot:toolbar>
      <el-checkbox
        style="margin-right: 1rem"
        :disabled="tableData.length === 0"
        v-model="allSelected"
        @change="selectAllClicked"
        label="Select all on page"
        size="large"
      ></el-checkbox>
      <button
        :disabled="tableData.length === 0 || selectedPlaylists.length === 0"
        class="
          btn btn-sm btn-color-muted btn-active btn-active-primary
          px-4
          me-1
          active
        "
        data-bs-toggle="modal"
        data-bs-target="#show_playlist_wishlist_modal"
      >
        Add to Wishlist
      </button>
      <button
        :disabled="tableData.length === 0 || selectedPlaylists.length === 0"
        @click="deletePlaylists"
        class="
          btn btn-sm btn-color-muted btn-active btn-active-primary
          px-4
          me-1
          active
        "
      >
        Remove Playlists
      </button>
    </template>
    <template v-slot:body>
      <el-table
        @sort-change="sortResults"
        v-loading="loading"
        :fit="true"
        size="small"
        :data="tableData"
        tooltip-effect="light"
        stripe
        style="width: 100%"
        header-cell-class-name="table-header-text"
        :row-class-name="tableRowClassName"
        :cell-class-name="tableCellClassName"
      >
        <el-table-column
          label="Select"
          width="80"
          fixed
          v-if="showToolbarButtons"
        >
          <template #default="scope">
            <el-checkbox
              :model-value="
                selectedPlaylists.includes(scope.row.curatorPlaylistID)
              "
              @change="
                checkBoxValueChanged($event, scope.row.curatorPlaylistID)
              "
              size="large"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column
          label="Name"
          fixed
          width="200"
          prop="name"
          sortable="custom"
        >
          <template #default="scope">
            <p
              class="truncate"
              data-tag="p"
              title=""
              data-tooltip="true"
              :data-text="scope.row.name ? scope.row.name : ''"
            >
              {{ scope.row.name ? scope.row.name : "N/A" }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Curator" sortable="custom" prop="owner_name">
          <template #default="scope">
            <p
              class="truncate"
              data-tag="p"
              title=""
              data-tooltip="true"
              :data-text="scope.row.owner_name ? scope.row.owner_name : ''"
            >
              {{ scope.row.owner_name ? scope.row.owner_name : "N/A" }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Followers" sortable="custom" prop="followers">
          <template #default="scope">
            <p
              class="truncate"
              data-tag="p"
              title=""
              data-tooltip="true"
              :data-text="scope.row.followers ? scope.row.followers : ''"
            >
              {{ scope.row.followers ? scope.row.followers : "N/A" }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          label="Total Tracks"
          sortable="custom"
          prop="num_track"
        >
          <template #default="scope">
            <p
              class="truncate"
              data-tag="p"
              title=""
              data-tooltip="true"
              :data-text="scope.row.num_track ? scope.row.num_track : ''"
            >
              {{ scope.row.num_track ? scope.row.num_track : "N/A" }}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="tags" label="Genres" width="400">
          <template #default="scope">
            <el-tag
              v-for="(tag, index) in scope.row.tags"
              :key="index"
              size="small"
              class="mx-1"
              effect="dark"
            >
              {{ tag.name }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="mt-7 mb-7"
        v-model:currentPage="localPagination.offset"
        v-model:page-size="localPagination.limit"
        :page-sizes="[5, 10, 20, 50, 100]"
        :page-size="localPagination.limit"
        :small="true"
        layout="total, sizes, prev, pager, next"
        :total="$props.getCount ? $props.getCount : 1"
        @size-change="paginationChanged"
        @current-change="paginationChanged"
      >
      </el-pagination>
    </template>
  </CardToolbar>
  <PlaylistWishlistModal
    v-if="chartmetricID"
    :selected-playlists="
      tableData
        .filter((playlist) => selectedPlaylists.includes(playlist.id))
        .map((g) => g)
    "
    :chartmetricID="chartmetricID"
    @updatePlaylists="updatePlaylists"
  />
</template>
